import utils from './utils';
import axios from 'axios';
import TweenLite from 'gsap';
import inView from 'in-view';

const body = document.body;
let ajaxGrid;
let resetBtn;
let loadMoreBtn;
let currentActiveFilters = [];
let pageIndex = 1;

function toggleClass(filter) {
  utils.toggleClass(filter, 'is-focused');
}

function showResults(data, replace) {
  let items = [];

  if (data.length) {
    data.forEach(item => {
      const title = item.title;
      const imageUrl = item.image ? item.image : `${location.origin}/images/test.jpg`;
      const snippet = item.snippet;

      const itemDiv = `<div class="col col-m-6 col-xl-4">
                        <div class="news-card ajax-card scroll-reveal">
                          <div class="hover-anim">
                            <img src=${imageUrl} alt="" class="news-card__image">
                            <div class="news-card__content">
                              <a href=${item.url} class="news-card__link">
                                <h4 class="heading heading--m heading--blue">${title}</h4>
                              </a>
                              <div class="news-card__text">${snippet}</div>
                              <div class="news-card__more">
                                <span class="heading heading--xs">Read more</span>
                                <span class="news-card__arrow"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>`
      items.push(itemDiv)
    })
  } else {
    items = [
      `<div class="col filters__col filters__col--center">No results</div>`
    ]
  }

  if (replace) {
    ajaxGrid.innerHTML = items.join('');
  } else {
    ajaxGrid.insertAdjacentHTML('beforeend', items.join(''))
  }

  if (!data.length) {
    body.classList.add('no-results');
  } else {
    body.classList.remove('no-results');
  }

  inView('.scroll-reveal').check();
}

function updateFilters(filter, option) {
  const isFilterChild = filter.classList.contains('filter--child');

  if (!isFilterChild) {
    const currentSubFilter = document.querySelector('.parent-is-active');
    if (currentSubFilter) {
      currentSubFilter.classList.remove('parent-is-active');
    }
  }

  const subFilter = document.querySelector(`[data-parent="${option}"]`)
  if (subFilter) {
    subFilter.classList.add('parent-is-active');
    const selectEl = subFilter.querySelector('.js-filter-select');
    selectEl.selectedIndex = 0;
  }
}

const filter = {
  init() {
    const filterSelects = Array.from(document.querySelectorAll('.js-filter-select'))
    ajaxGrid = document.querySelector('#ajax-grid');
    resetBtn = document.querySelector('.js-reset-filters');
    loadMoreBtn = document.querySelector('#load-more');

    if (loadMoreBtn) {
      loadMoreBtn.addEventListener('click', function(e) {
        body.classList.add('loading-entries');
        e.target.disabled = true;

        const currentSection = e.target.dataset.section;
        let requestUrl;

        pageIndex++

        if (currentActiveFilters.length) {
          requestUrl = `/filter-entries.json?section=${currentSection}&page=${pageIndex}&q=${currentActiveFilters}`;
        } else {
          requestUrl = `/section-entries.json?section=${currentSection}&page=${pageIndex}&offset=1`;
        }

        axios.get(requestUrl).then(function(res) {
          body.classList.remove('loading-entries');
          e.target.disabled = false;
          const data = res.data.data;
          showResults(data)

          const pages = res.data.meta.pagination.total_pages;
          if (pageIndex === pages) {
            body.classList.add('no-more-entries')
          }
        })
      });
    }

    if (filterSelects.length) {
      filterSelects.forEach(select => {
        const filter = select.closest('.js-filter');
        const currentSection = filter.dataset.section;

        select.addEventListener('change', function(e) {
          body.classList.add('filter-active');
          filter.classList.add('is-disabled');
          select.disabled = true;

          const option = e.target.value;
          let query;
          let requestUrl;

          pageIndex = 1;

          // If subFilter
          if (filter.dataset.parent.length) {
            // pageIndex = 1;
            query = `${filter.dataset.parent}, ${option}`;
            currentActiveFilters = query;
            requestUrl = `/filter-entries.json?section=${currentSection}&q=${query}&page=${pageIndex}`;
          } else {
            // If primary filter
            if (option.length) {
              query = option;
              currentActiveFilters = query;
              requestUrl = `/filter-entries.json?section=${currentSection}&q=${query}&page=${pageIndex}`;
            } else {
              requestUrl = `/section-entries.json?section=${currentSection}&page=${pageIndex}`;
            }
          }

          axios.get(requestUrl).then(function(res) {
            body.classList.remove('filter-active');
            filter.classList.remove('is-disabled');
            select.disabled = false;
            const data = res.data.data;
            showResults(data, true);
            updateFilters(filter, option);

            const pages = res.data.meta.pagination.total_pages;
            if (pageIndex === pages) {
              body.classList.add('no-more-entries')
            } else {
              pageIndex++
            }
          })
        });

        select.addEventListener('focus', function() {
          toggleClass(filter)
        });

        select.addEventListener('blur', function() {
          toggleClass(filter)
        });

        filter.addEventListener('mouseenter', function() {
          toggleClass(filter)
        });

        filter.addEventListener('mouseleave', function() {
          toggleClass(filter)
        });
      })

      resetBtn.addEventListener('click', function (e) {
        body.classList.add('loading-entries');
        e.target.disabled = true;

        filterSelects.forEach(filter => {
          filter.selectedIndex = 0;
          const currentSubFilter = document.querySelector('.parent-is-active');
          if (currentSubFilter) {
            currentSubFilter.classList.remove('parent-is-active');
          }
        })

        currentActiveFilters = [];
        pageIndex = 1;

        const currentSection = e.target.dataset.section;
        const requestUrl = `/section-entries.json?section=${currentSection}&page=${pageIndex}&offset=1`;

        axios.get(requestUrl).then(function(res) {
          body.classList.remove('loading-entries');
          e.target.disabled = false;
          const data = res.data.data;
          showResults(data, true)

          const pages = res.data.meta.pagination.total_pages;
          if (pages > 1) {
            body.classList.remove('no-more-entries');
          }
        })
      });
    }
  }
}

export default filter

import TweenLite from 'gsap';

function scrollTo(e) {
  e.preventDefault();
  const link = e.target.closest('.js-section-link');
  const selector = link.getAttribute('href');
  const sectionLink = document.querySelector(selector);
  const header = document.getElementById('header');
  const offset = header.offsetHeight;
  console.log(offset);

  if (!sectionLink) {
    return;
  }
  TweenLite.to(window, 0.5, {scrollTo: {y: sectionLink, offsetY: offset}});
}

export default function() {
  const links = Array.from(document.querySelectorAll('.js-section-link'));
  links.forEach(item => item.addEventListener('click', scrollTo));
}

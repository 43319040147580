import search from './search';
import utils from './utils';
import { TweenLite, TimelineLite } from 'gsap';

const menuBtn = document.querySelector('#menu-btn');
const mobileNavEl = document.querySelector('#mobile-nav');
const body = document.body;
const navItems = mobileNavEl.querySelectorAll('.js-mobile-nav-item');

function toggleNav() {
  utils.toggleClass(body, 'menu-is-active')

  if (body.classList.contains('menu-is-active')) {
    search.hideSearch();
    const fadeInTl = new TimelineLite()
    fadeInTl.staggerFromTo(navItems, 0.5, {opacity: 0, x: -20}, {opacity: 1, x: 0}, 0.015)
  }
}

const mobileNav = {
  hideNav() {
    body.classList.remove('menu-is-active');
  },

  init() {
    menuBtn.addEventListener('click', function(e) {
      toggleNav();
    });
  }
}

export default mobileNav

import mobileNav from './mobileNav';
import nav from './nav';
import utils from './utils';
import axios from 'axios';
import TweenLite from 'gsap';

const body = document.body;
const searchButton = document.querySelector('#search-button');
const form = document.querySelector('#search-form');
const input = document.querySelector('#search-input');
const searchHeading = document.querySelector('#search-heading');
const searchNum = document.querySelector('#search-number');
const searchContainer = document.querySelector('#search');
const results = document.querySelector('#search-results');

function toggleSearch() {
  utils.toggleClass(body, 'search-is-active');
  body.classList.contains('search-is-active') ? input.focus() : input.blur()
}

function showResults(data, input) {
  let items = [];

  data = data.filter(item => {
    return item.url
  })

  // data = data.sort(function(a, b) {
  //   const titleA = a.title.toLowerCase(); // ignore upper and lowercase
  //   const titleB = b.title.toLowerCase(); // ignore upper and lowercase
  //
  //   if (titleA < titleB) {
  //     return -1;
  //   }
  //
  //   if (titleA > titleB) {
  //     return 1;
  //   }
  //
  //   // names must be equal
  //   return 0;
  // });

  data.forEach(item => {
    const itemDiv = `<a href=${item.url} class="search__result">
                      <div class="search__result-inner">
                        <h3 class="search__heading">${item.title}</h3>
                        <div class="search__snippet">${item.snippet}</div>
                      </div>
                    </a>`;
    items.push(itemDiv)
  })

  results.innerHTML = items.join('');
  searchHeading.innerHTML = 'search results';
  searchNum.innerHTML = `${data.length} results for '${input}'`;
  TweenLite.staggerTo('.search__result', 0.5, {opacity: 1, x: 0}, 0.05);
}

const search = {
  hideSearch() {
    body.classList.remove('search-is-active');
    input.blur();
  },

  init() {
    if (searchButton) {
      document.addEventListener('keydown', (e) => {
        const searchIsOpen = document.querySelector('.search-is-active')
        if (e.code === 'Escape' && searchIsOpen) {
          this.hideSearch();
        }
      });

      searchButton.addEventListener('click', function(e) {
        mobileNav.hideNav();
        nav.hideNav();
        toggleSearch();
      });

      form.addEventListener('submit', function(e) {
        e.preventDefault();
        form.classList.add('is-searching')
        input.disabled = true;

        axios.get(`/search.json?q=${input.value}`).then(function(res) {
          form.classList.remove('is-searching')
          input.disabled = false;
          const data = res.data.data;
          showResults(data, input.value)
        })
      });
    }
  }
}

export default search

import inView from 'in-view';

const scrollReveal = {
  init() {
    inView('.scroll-reveal').on('enter', (el) => {
      el.classList.add('is-visible')
    })

    inView('.scroll-reveal-parent > *').on('enter', (el) => {
      el.classList.add('is-visible')
    })
  }
}

export default scrollReveal

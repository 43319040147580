import { TimelineLite, TweenLite } from 'gsap';
import { debounce } from 'lodash';

let loop;
let carousel;
let prev;
let next;
let imagesContainer;
let index;
let scrollWidth;
let offsetWidth;
let images;
let currentTweens;
let counterEl;
let captionsContainer;
let captions;
let captionLinks;
let captionsScrollWidth;
let captionsOffsetWidth;

function numberPad(num) {
  let s = String(num);
  while (s.length < 2) {s = '0' + s;}
  return s;
}

function updateNumbers() {
  counterEl.innerHTML = numberPad(index+1)
}

function prevSlide(userTabbing) {
  currentTweens = TweenLite.getTweensOf([imagesContainer, captionsContainer], true);
  currentTweens.forEach(tween => {
    tween.progress(1).kill()
  })

  if (index > 0) {
    index--;
  } else {
    index = images.length-1;
  }

  const prevTl = new TimelineLite();
  prevTl
    .set(images[index], {x: `-=${scrollWidth}`})
    .set(captions[index], {x: `-=${captionsScrollWidth}`})
    .to(imagesContainer, 0.5, {x: `+=${offsetWidth}`})
    .to(captionsContainer, 0.5, {x: `+=${captionsOffsetWidth}`}, '-=0.5')
    .add(() => {
      if (userTabbing) {
        captionLinks[index].focus({preventScroll:true})
      }
    })

  updateNumbers();
}

function nextSlide(userTabbing) {
  currentTweens = TweenLite.getTweensOf([imagesContainer, captionsContainer], true);
  currentTweens.forEach(tween => {
    tween.progress(1).kill()
  })

  const nextTl = new TimelineLite();
  nextTl
    .to(imagesContainer, 0.5, {x: `-=${offsetWidth}`})
    .to(captionsContainer, 0.5, {x: `-=${captionsOffsetWidth}`}, '-=0.5')
    .set(images[index], {x: `+=${scrollWidth}`})
    .set(captions[index], {x: `+=${captionsScrollWidth}`})
    .add(() => {
      if (userTabbing) {
        captionLinks[index].focus({preventScroll:true})
      }
    })

  if (index < images.length -1) {
    index++;
  } else {
    index = 0;
  }

  updateNumbers();
}

function handleClick(option, userTabbing) {
  if (loop) {
    stopLoop()
  }

  const id = this ? this.dataset.id : option;

  switch (id) {
    case 'prev':
      prevSlide(userTabbing);
      break;
    case 'next':
      nextSlide(userTabbing);
      break;
  }
}

function stopLoop() {
  clearInterval(loop)
  loop = null
}

function initLoop() {
  loop = setInterval(nextSlide, 6000);
}

const heroCarousel = {
  initCarousel() {
    prev = prev || carousel.querySelector('.js-hero-carousel-prev');
    next = next || carousel.querySelector('.js-hero-carousel-next');
    imagesContainer = imagesContainer || carousel.querySelector('.js-hero-carousel-images');
    images = images || Array.from(carousel.querySelectorAll('.js-hero-carousel-image'));
    captionsContainer = captionsContainer || carousel.querySelector('.js-hero-carousel-captions');
    captions = captions || Array.from(carousel.querySelectorAll('.js-hero-carousel-caption'));
    captionLinks = captionLinks || captions.map(caption => caption.querySelector('a'));

    TweenLite.set([imagesContainer, images], {clearProps: 'transform'})
    TweenLite.killTweensOf([imagesContainer, images])

    index = 0;
    scrollWidth = imagesContainer.scrollWidth;
    offsetWidth = imagesContainer.offsetWidth;
    counterEl = counterEl || carousel.querySelector('.js-hero-carousel-counter');
    captionsScrollWidth = captionsContainer.scrollWidth;
    captionsOffsetWidth = captionsContainer.offsetWidth;
    updateNumbers()
  },

  init() {
    carousel =  document.querySelector('.js-hero-carousel');

    if (carousel) {
      this.initCarousel();

      [prev, next].forEach(item => {
        item.addEventListener('click', handleClick);
      })

      let currentTabbed;

      window.addEventListener('keydown', function(e) {
        if (e.code === 'Tab') {
          if (e.shiftKey) {
            if (document.activeElement == captionLinks[index] && document.activeElement !== captionLinks[0]) {
              e.preventDefault();
              handleClick('prev', true)
            }
          } else {
            if (document.activeElement == captionLinks[index] && document.activeElement !== captionLinks[captionLinks.length -1]) {
              e.preventDefault();
              handleClick('next', true)
            }
          }
        }
      });

      window.addEventListener('resize', debounce(function(e) {
        heroCarousel.initCarousel();
      }, 250));

      initLoop();
    }
  }
}

export default heroCarousel

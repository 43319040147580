import TweenLite from 'gsap';

function backTop() {
  TweenLite.to(window, 0.5, {scrollTo:0});
}

export default function() {
  const buttons = Array.from(document.querySelectorAll('.js-back-top'));
  buttons.forEach(item => item.addEventListener('click', backTop));
}

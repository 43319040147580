import search from './search';
import utils from './utils';
import { TweenLite, TimelineLite } from 'gsap';

const navEl = document.querySelector('.js-nav-list');
const topLevelNavLinks = document.querySelectorAll('.js-nav-btn.level-1');
const navLine = document.querySelector('.js-nav-line');

let direction = "fromLeft";
let oldx = 0;

function mousemove(e) {
  if (e.pageX < oldx) {
    direction = "fromRight"
  } else if (e.pageX > oldx) {
    direction = "fromLeft"
  }

  oldx = e.pageX;
}

function animateItems(items) {
  const tl = new TimelineLite();
  tl.staggerFromTo(items, 0.5, {opacity: 0, y: 20}, {opacity: 1, y: 0}, 0.075)
}

function toggleNav(item) {
  const currentActive = navEl.querySelector('.nav-item-is-active');
  const parentEl = item.closest('.js-nav-item.level-1');
  const children = parentEl.querySelectorAll('.js-nav-item');

  if (currentActive) {
    currentActive.classList.remove('nav-item-is-active');

    if (currentActive !== parentEl) {
      parentEl.classList.add('nav-item-is-active');
      animateItems(children)
    }
  } else {
    parentEl.classList.add('nav-item-is-active');
    animateItems(children)
  }
}

function animateLineIn(e) {
  const width = this.offsetWidth - 34;
  const x = this.offsetLeft + 17;

  if (e.type === 'focus' && e.relatedTarget) {
    if (e.relatedTarget.id === 'search-button') {
      direction = 'fromRight'
    } else if (e.relatedTarget.id === 'logo-link') {
      direction = 'fromLeft'
    }
  }

  switch (direction) {
    case 'fromLeft':
      TweenLite.to(navLine, 0.3, {ease: Power1.easeOut, x: x, width: width, scaleX: 1, transformOrigin: 'left'})
      break;
    case 'fromRight':
      TweenLite.to(navLine, 0.3, {ease: Power1.easeOut, x: x, width: width, scaleX: 1, transformOrigin: 'right'})
      break;
  }
}

function animateLineOut(e) {
  const x = this.offsetLeft;

  switch (direction) {
    case 'fromLeft':
      TweenLite.to(navLine, 0.3, {ease: Power1.easeOut, scaleX: 0, transformOrigin: 'right'})
      break;
    case 'fromRight':
      TweenLite.to(navLine, 0.3, {ease: Power1.easeOut, scaleX: 0, transformOrigin: 'left'})
      break;
  }
}

const nav = {
  hideNav() {
    // Todo this needs drying
    const navEl = document.querySelector('.js-nav-list');
    const currentActive = navEl.querySelector('.nav-item-is-active');

    if (currentActive) {
      currentActive.classList.remove('nav-item-is-active');
    }
  },

  init() {
    document.addEventListener('keydown', (e) => {
      const navIsOpen = document.querySelector('.nav-item-is-active')
      if (e.code === 'Escape' && navIsOpen) {
        this.hideNav();
      }
    });

    document.addEventListener('mousemove', mousemove);
    topLevelNavLinks.forEach(item => {
      item.addEventListener('mouseenter', function(e) {
        const width = this.offsetWidth - 34;
        const x = this.offsetLeft + 17;

        switch (direction) {
          case 'fromLeft':
            TweenLite.to(navLine, 0.3, {ease: Power1.easeOut, x: x, width: width, scaleX: 1, transformOrigin: 'left'})
            break;
          case 'fromRight':
            TweenLite.to(navLine, 0.3, {ease: Power1.easeOut, x: x, width: width, scaleX: 1, transformOrigin: 'right'})
            break;
        }
      });

      item.addEventListener('mouseenter', animateLineIn);
      item.addEventListener('focus', animateLineIn);
      item.addEventListener('mouseleave', animateLineOut);

      item.addEventListener('click', function() {
        toggleNav(item);
        search.hideSearch();
      });

      item.addEventListener('blur', function(e) {
        if (!e.relatedTarget) {
          nav.hideNav(item);
        } else {
          animateLineOut.bind(this)(e);
        }
      });
    })
  }
}

export default nav

import { TimelineLite, TweenLite } from 'gsap';
import { debounce } from 'lodash';

let loop;
let carousel;
let prev;
let next;
let imagesContainer;
let index;
let scrollWidth;
let offsetWidth;
let images;
let currentTweens;
let counterEl;

function numberPad(num) {
  let s = String(num);
  while (s.length < 2) {s = '0' + s;}
  return s;
}

function updateNumbers() {
  counterEl.innerHTML = numberPad(index+1)
}

function prevSlide() {
  currentTweens = TweenLite.getTweensOf(imagesContainer, true);
  currentTweens.forEach(tween => {
    tween.progress(1).kill()
  })

  if (index > 0) {
    index--;
  } else {
    index = images.length-1;
  }

  const prevTl = new TimelineLite();
  prevTl
    .set(images[index], {x: `-=${scrollWidth}`})
    .to(imagesContainer, 0.5, {x: `+=${offsetWidth}`})

  updateNumbers();
}

function nextSlide() {
  currentTweens = TweenLite.getTweensOf(imagesContainer, true);
  currentTweens.forEach(tween => {
    tween.progress(1).kill()
  })

  const nextTl = new TimelineLite();
  nextTl
    .to(imagesContainer, 0.5, {x: `-=${offsetWidth}`})
    .set(images[index], {x: `+=${scrollWidth}`})

  if (index < images.length -1) {
    index++;
  } else {
    index = 0;
  }

  updateNumbers();
}

function handleClick(e) {
  if (loop) {
    stopLoop()
  }

  const id = this.dataset.id;
  switch (id) {
    case 'prev':
      prevSlide();
      break;
    case 'next':
      nextSlide();
      break;
  }
}

function stopLoop() {
  clearInterval(loop)
  loop = null
}

function initLoop() {
  loop = setInterval(nextSlide, 10000);
}

const imageCarousel = {
  initCarousel() {
    prev = prev || carousel.querySelector('.js-image-carousel-prev');
    next = next || carousel.querySelector('.js-image-carousel-next');
    imagesContainer = imagesContainer || carousel.querySelector('.js-image-carousel-images');
    images = images || Array.from(carousel.querySelectorAll('.js-image-carousel-image'));

    TweenLite.set([imagesContainer, images], {clearProps: 'transform'})
    TweenLite.killTweensOf([imagesContainer, images])

    index = 0;
    scrollWidth = imagesContainer.scrollWidth;
    offsetWidth = imagesContainer.offsetWidth;
    counterEl = counterEl || carousel.querySelector('.js-image-carousel-counter');
    updateNumbers()
  },

  init() {
    carousel =  document.querySelector('.js-image-carousel');

    if (carousel) {
      this.initCarousel();

      [prev, next].forEach(item => {
        item.addEventListener('click', handleClick);
      })

      window.addEventListener('resize', debounce(function(e) {
        imageCarousel.initCarousel();
      }, 250));

      initLoop()
    }
  }
}

export default imageCarousel

import { TweenLite, TimelineLite } from 'gsap';
const headerAnimEls = Array.from(document.querySelectorAll('.header-anim'));
const letters = document.querySelector('#logo-letters');
const arrow = document.querySelector('#logo-arrow');
const logo = document.querySelector('#logo-link');

const header = {
  init() {
    const headerTl = new TimelineLite({delay: 0.5});
    headerTl.staggerFromTo(headerAnimEls, 0.5, {opacity: 0, y: 20}, {opacity: 1, y: 0}, "0.1")

    const logoTl = new TimelineLite({delay: 0.5});
    logoTl
      .fromTo(letters, 0.4, {opacity: 0, y: 10}, {opacity: 1, y: 0})
      .fromTo(arrow, 0.1, {opacity: 0, x: -5}, {opacity: 1, x: 0}, "-=0.1")
      .add(() => {
        TweenLite.set([letters, arrow], {clearProps: 'all'});
        logo.classList.remove('logo-intro-transition');
      })
  }
}

export default header

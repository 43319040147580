import scrollReveal from './modules/scrollReveal';
import utils from './modules/utils';
import filter from './modules/filter';
import mobileNav from './modules/mobileNav';
import nav from './modules/nav';
import cardCarousel from './modules/cardCarousel';
import heroCarousel from './modules/heroCarousel';
import imageCarousel from './modules/imageCarousel';
import cardModal from './modules/cardModal';
import textVideoModal from './modules/textVideoModal';
import header from './modules/header';
import search from './modules/search';
import top from './modules/top';
import sectionLink from './modules/section-link';
import videoHero from './modules/video-hero';
top();
sectionLink();
videoHero();

const rootEl = document.documentElement;
rootEl.classList.remove('no-js');

window.addEventListener('load', () => {
  document.body.classList.remove('is-loading');
  header.init();
  scrollReveal.init();
  filter.init();
  mobileNav.init();
  nav.init();
  cardCarousel.init();
  heroCarousel.init();
  imageCarousel.init();
  cardModal.init();
  textVideoModal.init();
  search.init();
})

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

if ('scrollRestoration' in history) {
  // Back off, browser, I got this...
  history.scrollRestoration = 'manual';
}

import utils from './utils';
const images = Array.from(document.querySelectorAll('.js-text-video-image'));
const body = document.body;
const videoEls = Array.from(document.querySelectorAll('.js-text-video-modal iframe'));
let playerArray = [];

window.onYouTubeIframeAPIReady = function() {
  videoEls.forEach(el => initVideo(el));
}

function initVideo(el) {
  const videoId = el.src.split('embed/')[1];
  const playerObject = {};
  playerObject.ready = false;
  playerObject.src = el.src;
  playerObject.player = new YT.Player(el.parentElement, {
    videoId: videoId,
    enablejsapi: 1,
    origin: window.location.origin,
    events: {
      'onReady': () => {
        playerObject.ready = true;
      },
    }
  });
  playerArray.push(playerObject);
}

function showModal(modal) {
  // In case user is tabbing, prevent 2 modals from being open at the same time
  hideModal();
  modal.classList.add('modal-is-active');
  body.classList.add('modal-is-open');
}

function hideModal() {
  const modal = document.querySelector('.modal-is-active');
  if (!modal) {
    return;
  }
  modal.classList.remove('modal-is-active');
  body.classList.remove('modal-is-open');
  const video = modal.querySelector('iframe');
  if (!video) {
    return;
  }
  const playerObject = playerArray.find(item => video.src.includes(item.src));
  if (playerObject) {
    if (playerObject.ready) {
      playerObject.player.stopVideo();
    }
  }
}

const cardModal = {
  init() {
    images.forEach(function(image) {
      const id = image.dataset.modal;
      const modal = document.querySelector(`#${id}`);
      const close = modal.querySelector(`.js-close-text-video-modal`);

      image.addEventListener('click', function(e) {
        showModal(modal);
        close.tabIndex = 0;
      });

      close.addEventListener('click', function(e) {
        hideModal();
        close.tabIndex = -1;
      })
    });

    if (!videoEls.length) {
      return;
    }

    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.append(tag);
  }
}

export default cardModal;

import utils from './utils';
const cards = Array.from(document.querySelectorAll('.js-card-modal'));
const body = document.body;
const videoEls = Array.from(document.querySelectorAll('.js-person-video > iframe'));
let playerArray = [];

window.onYouTubeIframeAPIReady = function() {
  videoEls.forEach(el => initVideo(el));
}

function initVideo(el) {
  const videoId = el.src.split('embed/')[1];
  const playerObject = {};
  playerObject.ready = false;
  playerObject.src = el.src;
  playerObject.player = new YT.Player(el.parentElement, {
    videoId: videoId,
    enablejsapi: 1,
    origin: window.location.origin,
    events: {
      'onReady': () => {
        playerObject.ready = true;
      },
    }
  });
  playerArray.push(playerObject);
}

function handlePopState(event) {
  if (event.state && window.location.href.includes('who-we-are')) {
    // Check if there's a current active modal and if so, close it
    hideModal();
    // Check if state contains a modal and if so, show it
    const { modal = false } = event.state;
    if (!modal) {
      return;
    }
    const match = document.querySelector(`[data-modal="${modal}"]`);
    if (!match) {
      return;
    }
    showModal(match);
    const card = document.querySelector(`.js-card-modal[data-slug="${modal}"]`);
    card.scrollIntoView(false);
  }
}

function updateHistoryShow(modal) {
  const url = modal.dataset.url;
  const slug = modal.dataset.modal;
  history.pushState({
    url: url,
    modal: slug,
  }, null, url);
}

function updateHistoryClose(modal) {
  const url = modal.dataset.parentUrl;
  history.pushState({url: url}, null, url);
}

function showModal(modal) {
  // In case user is tabbing, prevent 2 modals from being open at the same time
  hideModal();
  modal.classList.add('modal-is-active');
  body.classList.add('modal-is-open');
}

function hideModal() {
  const modal = document.querySelector('.modal-is-active');
  if (!modal) {
    return;
  }
  modal.classList.remove('modal-is-active');
  body.classList.remove('modal-is-open');
  const video = modal.querySelector('iframe');
  if (!video) {
    return;
  }
  const playerObject = playerArray.find(item => video.src.includes(item.src));
  if (playerObject) {
    if (playerObject.ready) {
      playerObject.player.stopVideo();
    }
  }
}

function checkUrl() {
  const url = window.location.href;
  // Filter empty strings from array
  const segments = window.location.pathname.split('/').filter(item => item);
  // 3 segments means there's a person slug in the URL
  if (segments.length === 3) {
    const slug = segments[2];
    // Check if there's a matching modal
    const match = document.querySelector(`[data-modal="${slug}"]`);
    if (!match) {
      return;
    }
    showModal(match);
    const card = document.querySelector(`.js-card-modal[data-slug="${slug}"]`);
    card.scrollIntoView(false);
    history.replaceState({
      url: url,
      modal: slug,
    }, null, url);
  } else {
    history.replaceState({url: url}, null, url);
  }
}

const cardModal = {
  init() {
    if (!window.location.href.includes('staff-and-experts') && !window.location.href.includes('leaders')) {
      return;
    }
    window.addEventListener('popstate', handlePopState);
    // Check initial URL
    checkUrl();

    cards.forEach(function(card) {
      const cardUrl = card.dataset.slug;
      const modal = document.querySelector(`[data-modal=${cardUrl}]`);
      const close = modal.querySelector(`.js-close-card-modal`);

      card.addEventListener('click', function(e) {
        showModal(modal);
        updateHistoryShow(modal);
        close.tabIndex = 0;
      });

      close.addEventListener('click', function(e) {
        hideModal();
        updateHistoryClose(modal);
        close.tabIndex = -1;
      })
    });

    if (!videoEls.length) {
      return;
    }

    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.append(tag);
  }
}

export default cardModal;

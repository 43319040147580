import TweenLite from 'gsap';
import { debounce } from 'lodash';

let carousel;
let grid;
let inner;
let prev;
let next;
let innerScrollWidth;
let innerOffsetWidth;
let itemWidth;
let margin;
let totalItemWidth;;
let overflow;
let index;
let items;
let currentTweens;

function handleClick(e) {
  const id = this.dataset.id;
  switch (id) {
    case 'prev':
      if (next.disabled) {
        next.disabled = false;
      }

      currentTweens = TweenLite.getTweensOf(grid, true);
      currentTweens.forEach(tween => {
        tween.progress(1).kill()
      })

      TweenLite.to(grid, 0.5, {x: `+=${totalItemWidth}`})
      overflow = overflow + totalItemWidth;

      if (parseInt(overflow) === innerScrollWidth - innerOffsetWidth) {
        prev.disabled = true;
      }

      break;
    case 'next':
      if (prev.disabled) {
        prev.disabled = false;
      }

      currentTweens = TweenLite.getTweensOf(grid, true);
      currentTweens.forEach(tween => {
        tween.progress(1).kill()
      })

      TweenLite.to(grid, 0.5, {x: `-=${totalItemWidth}`})
      overflow = overflow - totalItemWidth;

      if (parseInt(overflow) === 0) {
        next.disabled = true;
      }

      break;
  }
}

const cardCarousel = {
  initCarousel() {
    grid = grid || carousel.querySelector('.js-card-carousel-grid');
    inner = inner || carousel.querySelector('.js-card-carousel-inner');
    prev = prev || carousel.querySelector('.js-card-carousel-prev');
    next = next || carousel.querySelector('.js-card-carousel-next');
    items = Array.from(grid.children);

    if (!items.length) {
      return
    }

    TweenLite.set([grid, items], {clearProps: 'all'})
    TweenLite.killTweensOf([grid, items])

    innerScrollWidth = inner.scrollWidth;
    innerOffsetWidth = inner.offsetWidth;
    itemWidth = items[0].getBoundingClientRect().width;
    margin = parseFloat(window.getComputedStyle(items[0]).marginLeft) * 2;
    totalItemWidth = itemWidth + margin;
    overflow = innerScrollWidth - innerOffsetWidth;
    index = 0;
    prev.disabled = true;
  },

  init() {
    carousel = document.querySelector('.js-card-carousel');

    if (carousel) {
      this.initCarousel();

      [prev, next].forEach(item => {
        item.addEventListener('click', handleClick);
      })

      window.addEventListener('resize', debounce(function(e) {
        cardCarousel.initCarousel();
      }, 250));
    }
  }
}

export default cardCarousel;
